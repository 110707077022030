import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Layout } from '../components/Layout.js';
import { Seo } from '../components/Seo.js';
import { Container } from '../components/Container.js';
import { ResumeIcon } from '../components/icons/Resume.js';
export const _frontmatter = {
  "title": "David Valles Resume",
  "template": "page"
};
const layoutProps = {
  _frontmatter
};

const MDXLayout = ({
  children
}) => <Layout>
    <Seo title="David Valles | Resume" />
    <Container>
      <div className="prose max-w-none">{children}</div>
    </Container>
  </Layout>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`David Valles `}<a href="/david-valles-resume.pdf"><ResumeIcon className="w-6 h-6" mdxType="ResumeIcon" /></a></h1>
    <p>{`Software engineer, experienced building modern React-based web applications.
Passionate about intuitive UI/UX, full-stack web technologies and helping people
and organizations reach their goals.`}</p>
    <p><a parentName="p" {...{
        "href": "mailto:davidtjvalles@gmail.com"
      }}>{`Email`}</a>{` | `}<a parentName="p" {...{
        "href": "https://dtjv.io"
      }}>{`Website`}</a>{` |
`}<a parentName="p" {...{
        "href": "https://github.com/dtjv"
      }}>{`GitHub`}</a>{` |
`}<a parentName="p" {...{
        "href": "https://linkedin.com/in/davevalles"
      }}>{`LinkedIn`}</a></p>
    <h2>{`EXPERIENCE`}</h2>
    <h3>{`Frontend Engineer `}<em parentName="h3">{`(freelance, 2021)`}</em></h3>
    <p><em parentName="p">{`Terra Gardens`}</em>{` | `}<a parentName="p" {...{
        "href": "https://github.com/dtjv/terra"
      }}>{`docs`}</a>{` |
`}<a parentName="p" {...{
        "href": "https://terra-demo.vercel.app"
      }}>{`demo`}</a></p>
    <ul>
      <li parentName="ul">{`Developed a full-stack, scheduling app for a garden nursery’s delivery
service. Provides a stepped-form for accurate data entry, computes available
delivery times and features drag-n-drop functionality for intuitive schedule
management.`}</li>
      <li parentName="ul">{`Built with TypeScript, Next.js, Chakra-UI, React-DnD, React Query, React Hook
Form, and MongoDB.`}</li>
    </ul>
    <h3>{`Frontend Engineer `}<em parentName="h3">{`(freelance, 2017-2020)`}</em></h3>
    <p><em parentName="p">{`BMI Fitness, LLC`}</em>{` | `}<a parentName="p" {...{
        "href": "https://github.com/dtjv/bmi"
      }}>{`docs`}</a>{` |
`}<a parentName="p" {...{
        "href": "https://dtjv.io/bmi"
      }}>{`website`}</a></p>
    <ul>
      <li parentName="ul">{`Developed a fitness studio website to present services, staff bios, and
published content.`}</li>
      <li parentName="ul">{`Built with Jekyll, Materialize and Sass. Integrated Formspree.io for form
routing and Forestry.io for content management.`}</li>
    </ul>
    <h3>{`Software Engineer `}<em parentName="h3">{`(freelance, 2015-2017)`}</em></h3>
    <p><em parentName="p">{`Club One Fitness, Inc.`}</em></p>
    <ul>
      <li parentName="ul">{`Created a Node.js CLI to bundle client workout files (written in Markdown)
into one printable web page. (`}<a parentName="li" {...{
          "href": "https://github.com/dtjv/wod-cli"
        }}>{`docs`}</a>{`)`}</li>
      <li parentName="ul">{`Developed a jQuery and Firebase web app for trainers to collect and manage
client movement screening data. (`}<a parentName="li" {...{
          "href": "https://github.com/dtjv/fms-wsk"
        }}>{`docs`}</a>{` |
`}<a parentName="li" {...{
          "href": "https://fms-wsk.web.app/"
        }}>{`demo`}</a>{`)`}</li>
    </ul>
    <h3>{`Sofware Engineer `}<em parentName="h3">{`(freelance, 2015)`}</em></h3>
    <p><em parentName="p">{`St. Christopher's - Art Enrichment Program`}</em>{` |
`}<a parentName="p" {...{
        "href": "https://github.com/dtjv/art-enrichment"
      }}>{`docs`}</a></p>
    <ul>
      <li parentName="ul">{`Created a Google Sheet menu function to detect and report schedule conflicts
among proposed art classes.`}</li>
      <li parentName="ul">{`Built with JavaScript, Webpack and Google App Script API`}</li>
    </ul>
    <h3>{`Personal Trainer `}<em parentName="h3">{`(2005-2020)`}</em></h3>
    <p><em parentName="p">{`Club One Fitness / Forma Gym / Realm Fitness`}</em></p>
    <ul>
      <li parentName="ul">{`Developed and coached safe, appropriate and effective strength and
conditioning training for clientele.`}</li>
    </ul>
    <h2>{`PROJECTS`}</h2>
    <h3>{`Memowise`}</h3>
    <p><a parentName="p" {...{
        "href": "https://github.com/dtjv/memowise"
      }}>{`docs`}</a>{` | `}<a parentName="p" {...{
        "href": "https://memowise.vercel.app/"
      }}>{`demo`}</a></p>
    <ul>
      <li parentName="ul">{`Created a full-stack, responsive education web app offering algorithm-based
study, dynamic quizzes, and a personalized dashboard`}</li>
      <li parentName="ul">{`Built with React, Next.js, MongoDB, Tailwind CSS`}</li>
    </ul>
    <h3>{`Maximus`}</h3>
    <p><a parentName="p" {...{
        "href": "https://github.com/dtjv/maximus"
      }}>{`docs`}</a>{` |
`}<a parentName="p" {...{
        "href": "https://maximus-dtjv.netlify.app/"
      }}>{`demo`}</a></p>
    <ul>
      <li parentName="ul">{`Created a web app to create workouts and visualize them in an interactive bar
chart. Built with React and Chart.js`}</li>
    </ul>
    <h3>{`Travel Salem`}</h3>
    <p><a parentName="p" {...{
        "href": "https://github.com/dtjv/travel-salem"
      }}>{`docs`}</a>{` |
`}<a parentName="p" {...{
        "href": "https://travelsalem.vercel.app/"
      }}>{`demo`}</a></p>
    <ul>
      <li parentName="ul">{`Designed a travel site demo, with a focus on intuitive site navigation,
surfacing buried content and optimizing load speed.`}</li>
      <li parentName="ul">{`Built using Next.js and Tailwind CSS.`}</li>
    </ul>
    <h2>{`EDUCATION`}</h2>
    <h3>{`Cal Poly, San Luis Obispo`}</h3>
    <p><em parentName="p">{`Bachelor of Science, Computer Science`}</em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      